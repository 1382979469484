<template>
  <b-card no-body>
    <b-row cols="12" class="mb-2">
      <b-col cols="6">
        <h1
          v-if="pageTitle"
          class="
            text-purple
            font-weight-bold
            mb-1
            col-md-10 col-lg-10 col-xl-11
            mt-1
          "
          v-html="$t(pageTitle) ? $t(pageTitle) : ''"
        ></h1>
      </b-col>
     
      <div  class="d-flex justify-content-end col-md-6" v-if="showFilter">
        <b-form-group
          :label="$t('filter')"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 col-md-6 pr-0"
        >
          <b-input-group size="sm">
            <b-form-input
              @input="filterItems"
              id="filterInput"
              v-model.trim="filter"
              type="search"
              :placeholder="$t('type_to_search')"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="paginateItems('clear')">
                {{ $t("clear") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-row>

    <b-table
      v-if="!loader"
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="0"
      :items="data"
      :fields="headers"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @sort-changed="sortChanged"
      v-bind="$attrs"
      v-on="$listeners"
      custom-prop="any"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name"> Test </slot>
      <template #head()="scope">
        <div class="text-nowrap">{{ $t(scope.label) }}</div>
      </template>
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
    </b-table>
    <div v-else>
      <img src="/new-loader.svg" />
    </div>
    <b-card-body class="d-flex justify-content-end flex-wrap pt-2">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div>
          Show
          <b-form-select @change="paginateItems('clear')" v-model="perPage" id="per_page" :options="page_options"></b-form-select> items
        </div>
        <b-pagination
          @input="paginateItems"
          v-model.trim="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    
    <div align="end" v-if="showTasks"> 
         <b-link class="btn btn-outline-primary" to="/">
        {{ $t("next") }}
        <FeatherIcon icon="ChevronRightIcon"></FeatherIcon>
      </b-link>
    </div>
    <div align="end" v-else-if="!showTasks && $route.path != '/' && $route.path != '/market'"> 
      <b-link class="btn btn-outline-primary" to="/">
        <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
   {{ $t("back_to_dashboard") }}
 </b-link>
 </div>
   
  </b-card>
</template>

<script>

export default {
  name: "Table",
  components: {
    
  },
  data() {
    return {
      perPage: 200,
      page_options: [
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 200, text: '200' },
      ],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      tableKey: "initTable",
      filterOn: [],
      loader: false,
      renderPagination: true,
      pageTitle: {},
      headers: [],
      totalItems: 0,
      data: [],
      modalText: "",
      modalTitle: "",
      idToChange: "",
      approveModalAction: null,
      showTasks: true,
    };
  },
  render(h) {
    return h("b-table", {
      props: Object.assign(this.$attrs, this.$props),
      slots: this.$parent.$slots,
      scopedSlots: this.$parent.$scopedSlots,
      on: {
        // 'row-clicked': (item, index, event) => alert('clicked ' + index)
      },
    });
  },
  props: {
    title: String,
    fields: Array,
    url: String,
    showFilter: Boolean,
    updateData: Function,
    payload: Object,
  },

  computed: {
    sortOptions() {
      return this.headers
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.text, value: f.value }));
    },
    rows() {
      return this.totalItems;
    },
  },
 watch: {
    updateData(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit("updateProp");
        this.paginateItems();
      }
    },
  },
  created() {
    this.pageTitle = this.title;
    this.headers = this.fields ? this.fields : [];
    this.paginateItems();
 
      this.$http.post("/company/tasks").then((res) => {
        if(res){
          res.data.value.forEach((el) => {
          if (el.status &&
            el.status == 1 &&
            el.title != "Complete identification" &&
            el.visible == 1
          ) {
            this.showTasks = false;
          }
        });

        }
        
      });
   
  },
  methods: {
    myRowClickHandler() {
      this.$emit("myRowClickHandler");
    },
    callApproveModalAction() {
      alert($t("success"));
    },
    changeModalInfo(action, title, text, id) {
      this.approveModalAction = action;
      this.modalText = text;
      this.modalTitle = title;
      this.idToChange = id;
    },

    slotItemName(item) {
      return "custom-" + item.value;
    },
    paginateItems(type) {
      if (this.url) {
        if (type == "clear") {
          this.filter = "";
        }
        this.loader = true;
        this.$http.post(`${this.url}?page=${this.currentPage}&per_page=${this.perPage}`, this.payload).then((res) => {
          if (res) {
            if (res.data.value && res.data.value.data.data) {
              this.data = res.data.value.data.data;
              this.totalItems = res.data.value.data.total;
              this.currentPage = res.data.value.data.current_page;
            } else {
              this.data = res.data.value.data;
              this.totalItems = res.data.value.total;
              this.currentPage = res.data.value.current_page;
            }

            this.loader = false;
          }
        });
      }
    },
    sortChanged(e) {
      if (this.url) {
        this.$http
          .post(
            `${this.url}?sortDesc=${e.sortDesc ? e.sortDesc : e}&sortBy=${
              e.sortBy ? e.sortBy : e
            }`
          )
          .then((res) => {
            if (res) {
              if (res.data.value && res.data.value.data.data) {
                this.data = res.data.value.data.data;
                this.totalItems = res.data.value.data.total;
                this.currentPage = res.data.value.data.current_page;
              } else {
                this.data = res.data.value.data;
                this.totalItems = res.data.value.total;
                this.currentPage = res.data.value.current_page;
              }
            }
          });
      }
    },

    filterItems() {
      if (this.url) {
        this.loader = true;

        this.$http.post(`${this.url}?search=${this.filter}`).then((res) => {
          if (res) {
            if (res.data.value && res.data.value.data.data) {
              this.data = res.data.value.data.data;
              this.totalItems = res.data.value.data.total;
              this.currentPage = res.data.value.data.current_page;
            } else {
              this.data = res.data.value.data;
              this.totalItems = res.data.value.total;
              this.currentPage = res.data.value.current_page;
            }

            this.loader = false;
          }
        });
      }
    },
  },
};
</script>
